import React from 'react';
import { Link, graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
    IntroQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const ArbeitMitJuniorjugendlichen = ({ data }) => (
    <InnerPage title="Arbeit mit Juniorjugendlichen" description="">
        <IntroHolder
            title="Arbeit mit Juniorjugendlichen"
            subtitle="Sich für die jüngere und heranwachsende Generation engagieren"
            picture={data.introImage}
            breadcrumbs={{
                grandparent: 'Gemeinsames Handeln',
                parent: 'Jugend',
                'current-item': 'Arbeit mit Juniorjugendlichen',
            }}
        >
            <IntroQuote>
                Ein angemessenes Verständnis dieser Altersgruppe ist das
                selbstloser junger Menschen, die “einen ausgeprägten Sinn für
                Gerechtigkeit, Eifer, über das Universum zu lernen, und den
                Wunsch, beim Aufbau einer besseren Welt mitzuwirken” haben.
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Junge Menschen, besonders im Alter von zwölf bis fünfzehn
                    Jahren, erleben viele körperliche, intellektuelle und
                    emotionale Veränderungen.
                </p>
                <div>
                    <p>
                        Es stellen sich ihnen Fragen zu grundlegenden Themen,
                        wie z.B. wer sie sind, was ihre Absicht im Leben ist,
                        wie sie ihre Talente und Fähigkeiten für bedeutsame
                        Ziele einsetzen können – was sie beispielsweise
                        angesichts der Ungerechtigkeit in der Welt oder der
                        Zerstörung der Umwelt selbst beitragen können. Mit ihrer
                        Wissensbegierde und ihrem Enthusiasmus sind sie im
                        Grunde bereit, sich für Veränderungen in der Welt und
                        bei sich selbst zu engagieren.
                    </p>
                    <p>
                        <img
                            alt=""
                            src={data.collage.childImageSharp.fluid.src}
                            className="aligncenter"
                        />
                    </p>
                    <p>
                        In dieser Altersspanne von „Juniorjugendlichen“ bilden
                        sich Ansichten über den Einzelnen und die Gesellschaft
                        heraus, die ihr weiteres Lebens positiv prägen können.
                        Allerdings geht dies oft auch einher mit Gefühlen der
                        Sorge, des Unbehagens und des Zweifels, die zu
                        widersprüchlichem Verhalten führen können. Jugendliche
                        können die ihnen innewohnenden{' '}
                        <Link to="/woran-bahai-glauben/natur-des-menschen/">
                            Fähigkeiten der Seele
                        </Link>{' '}
                        und des Geistes aber so entwickeln, dass sie
                        Herausforderungen überwinden und zu Mitgestaltern beim
                        Aufbau einer neuen Gesellschaft werden. Sie brauchen
                        hierfür ein Umfeld, das ihre geistigen Kräfte stärkt und
                        ihre Begeisterung für das Lernen und Handeln zugunsten
                        bedeutungsvoller Ziele weckt und erhält.
                    </p>
                    <p>
                        In diesem Zusammenhang widmen sich Bahá’í-Jugendliche
                        und ihre Freunde in vielen Orten Deutschlands dem sog.
                        Juniorjugendprogramm. In Gruppen von Gleichaltrigen
                        unterstützen sie sich gegenseitig und mit geschulten
                        Trainern dabei, ihr Potenzial sowie ihre Fähigkeiten zu
                        entdecken und zu entfalten. Angeregt durch das
                        gemeinsame Studium von Texten führen sie Gespräche über
                        Werte und persönliche oder gesellschaftliche Themen, die
                        einen positiven, hoffnungsvollen Ausblick auf ihr Leben
                        und die Welt ermöglichen. Freundschaften entstehen und
                        werden gefestigt durch den Austausch in der Gruppe,
                        durch Spiele und Sport sowie durch Vorbereitung und
                        Durchführung sozialer Projekte zur Verbesserung der
                        Nachbarschaft.
                    </p>
                </div>
                <Reference>
                    <p>1. Material zu den Jungendkonferenzen 2013</p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default ArbeitMitJuniorjugendlichen;

export const query = graphql`
    query {
        introImage: file(
            relativePath: { eq: "arbeit-mit-juniorjugendlichen-feature.jpg" }
        ) {
            ...fluidImage
        }
        collage: file(
            relativePath: { eq: "arbeit-mit-juniorjugendlichen-img-1.jpg" }
        ) {
            ...fluidImage
        }
    }
`;
